import React from "react"
import { graphql, Link } from "gatsby"
import BackgroundImage from 'gatsby-background-image'

import Layout from '../components/Layout'
import "./../components/DynamicPage.css"




function GeneralTemplate(props) {
    let data = props.data.wpgraphql;
    
    function constructMetaData(page, currentPage) {
        let payload = {
            noIndex: page.seo.metaRobotsNoindex,
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://nextyounutrition.gr/${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://nextyounutrition.gr/${currentPage}`,
            fbType: "website",
            locale: 'el',
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>

            <Layout header={data.menu} footer={data.footer} appointment= {data.template.appCpt} metaData={constructMetaData(data.page, props.pageContext.currentPage)} >
                <div>
                    <div>
                        {data.page.dynamicPageAcf.introBackgroundImage && (
                            <BackgroundImage
                                Tag="section"
                                className="background-dm-container"
                                fluid={data.page.dynamicPageAcf.introBackgroundImage.imageFile.childImageSharp.fluid}
                                backgroundColor={`#040e18`}
                            >
                                <div>
                                    <h1>
                                        {data.page.title}
                                    </h1>
                                </div>
                            </BackgroundImage>
                        )}
                    </div>
                    <div className="container m-auto py-16 dynamic-content" dangerouslySetInnerHTML={{ __html: data.page.content }}></div>
                </div>
            </Layout>
        </div>
    )
}

export default GeneralTemplate;

export const pageQuery = graphql`
    query GET_DYNAMIC_PAGE($id: ID!) {
        wpgraphql {
            page(id: $id) {
                seo {
                    canonical
                    title
                    metaDesc
                    opengraphDescription
                    opengraphTitle
                    opengraphImage {
                        sourceUrl
                    }
                    metaRobotsNoindex
                }
                title
                content
                dynamicPageAcf{
                    introBackgroundImage{
                        altText
                        sourceUrl
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 2080, quality:80){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                }
            }
            template(id: "cG9zdDoyMDUw"){
                appCpt {
                    ...AppointmentFragment
                }
            }
            menu(id:"bmF2X21lbnU6MjI="){
                menuItems{
                    edges{
                        node{
                            url
                            label
                            childItems {
                                edges {
                                    node {
                                        label
                                        url
                                    }
                                }
                            }
                        }
                    }
                }
            }
            footer: template(id:"cG9zdDoyMjE5"){
                id
                title
                footerInformationTemplate{
                    address{
                        addressGoogleLink
                        addressLabel
                    }
                    hoursLabel
                    hoursTitle
                    informationTitle
                    supportEmail
                    telephoneNumber
                }
                footerAcf{
                    googlaMapsImage{
                        sourceUrl
                        altText
                        imageFile{
                            childImageSharp{
                                fluid(maxWidth: 750, quality:100){
                                    base64
                                    aspectRatio
                                    src
                                    srcSet
                                    srcWebp
                                    srcSetWebp
                                    sizes
                                }
                            }
                        }
                    }
                    city
                    callToActionText
                }
            }
        }
    }
`
